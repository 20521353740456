"use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function init() {
        // JavaScript to be fired on all pages
        var $body = $('body'); // -> Initialize fitvids

        $('.content-primary').fitVids(); // -> Initialize '.nav-login' drop-down menu

        $('.nav-login').each(function () {
          var $sl = $(this);
          var $slControl = $sl.find('.nav-login__control');
          $slControl.on('click', function () {
            $sl.toggleClass('nav-login--hidden');
          });
        }); // -> Initialize navigation tray

        $body.addClass('page--has-tray');
        var $control = $('<i class="tray-control fa fa-bars"></i>');
        $('.brand').after($control);
        var $tray = $('' + '<div class="tray tray--closed">' + '  <div class="tray__container">' + '    <div class="brand">' + '      <img class="brand__logo" src="/app/themes/sams-v2/dist/images/logo--white.svg" />' + '    </div>' + '    <i class="tray-control fa fa-bars"></i>' + '  </div>' + '</div>' + '');
        var $container = $tray.find('.tray__container');
        $container.append($('.cart-summary').clone());
        $container.append($('.social-links').clone());
        $container.append($('.nav-primary').clone());
        $body.append($tray);
        $('.tray-control').click(function () {
          $tray.toggleClass('tray--closed');
        }); // -> Initialize qTips on discount blocks

        $('.product .discounts').each(function () {
          var $discount = $(this);
          $discount.qtip({
            content: {
              title: $discount.find('span').text(),
              text: $discount.find('ol').html()
            },
            position: {
              my: 'bottom left',
              at: 'top center',
              target: $discount
            }
          });
          $discount.addClass('tipped');
        }); // -> Initialize tabs
      },
      finalize: function finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function init() {
        // JavaScript to be fired on the home page
        // --> Initialize Hero Carousel
        if ($(".carousel--hero .hero__item").length) {
          $(".carousel--hero .carousel__widget").slick({
            element: '.carousel__item',
            dots: false,
            arrows: true,
            infinite: true,
            autoPlaySpeed: 8000
          });
          $('body').addClass('has_hero');
        }
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    'contact': {
      init: function init() {
        var params = new URLSearchParams(window.location.search);
        var input = document.querySelector('.wpcf7-form-control[name="subject"]');

        if (input && !input.getAttribute('value').length) {
          input.setAttribute('value', params.get('subject'));
        }
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire('common'); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      }); // Fire common finalize JS

      UTIL.fire('common', 'finalize');
    }
  }; // Load Events

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.